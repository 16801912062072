header{
    @include media-breakpoint-down(lg){
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}
.sub-header {
    height: 500px;
    position: relative;
    overflow: hidden;
    // margin-top: $nav-mobile-height;
    z-index: 3;
    // @include media-breakpoint-up(xl) { margin-top: $nav-desktop-height; }

    &--half{
        height: 130px;
        @include media-breakpoint-up(sm) { 
            height: 250px;
        } 
        
        //media query sotto
    }

    // & .col{
    //     margin-top: auto;
    //     margin-bottom: auto;
    // }

    & .header-content{
        margin-top: 2%;
        // @include media-breakpoint-up(lg){
        //     margin-top: 2%;
        // }
    }
    &__icon{
        width: 60px;
        @include media-breakpoint-down(md){
            width: 30px;
        }
    }

    &__background{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        transform: skewY(-2.5deg);
        transform-origin: top left;
        background: {
            repeat: no-repeat;
            position: center;
            size: cover;
        }
    }

    &__content {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0 15px;
        position: absolute;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__svg { 
        height: 70px;
        width: 64.88px;
        @include media-breakpoint-up(sm) { 
            height: 150px;
            width: 139.02px;
        } 
    }

    &__col{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: wrap-reverse;
    }

    &__title {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        top: -100px;
        left: -100px;
        width: 0;
        height: 0;
        // color: $white;
        // font-size: 2.8rem;
        // text-align: center;
        // line-height: 1.33em;
        // margin-bottom: 0.20em;
        // text-transform: uppercase;
        // @include futura-FT;

        // @include media-breakpoint-up(md) { padding: 0; }

        // @include media-breakpoint-up(sm) { font-size: 2rem; }
        // @include media-breakpoint-up(lg) { font-size: 2.3rem; }
        // @include media-breakpoint-up(xl) { font-size: 2.8rem; }

        // &--left { text-align: left; margin: 0;}
    }

    &__subtitle {
        font-size: 15px;
        line-height: 1.41em;
        max-width: 443px;
        text-align: center;
        padding: 0 15px;
        color: $white;

        @include media-breakpoint-up(md) { padding: 0; }

    }
}

.scroll-bottom {
    justify-content: center;
    position: absolute;
    height: 2.75em;
    width: 2.75em;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    svg{ margin-top: 50%; transform: translateY(-50%); }

    &::before {
        position: absolute;
        content: "Scroll down";
        text-transform: uppercase;
        color: #fff;
        font-size: 0.75rem;
        line-height: 1.5em;
        top: -2em;
        width: max-content;
        letter-spacing: 1px;
    }
}

@media (min-width: 1600px){
    .sub-header--half {
        height: 350px;
    }
    .sub-header__svg {
        height: 200px;
        width: 185.36px;
    }
    // .sub-header .header-content{
    //     margin-top: 5%;
    // }
}