.card {
    border: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    // @include diagonal-bg();
    // background-image: linear-gradient($primary, $primary);
    // background-size: 0;
    background-color: $white;
    transition: all 0.3s ease-in-out;
    // &::after{
    //     content: "";
    //     @include trinagle();
    //     top: 50%;
    //     left: 66%;
    //     position: absolute;
    //     transform: translate(-50%,-50%);
    //     // @include media-breakpoint-up(sm){
    //     //     left: 66%;
    //     // }
    // }

    @include media-breakpoint-up(lg){
        &:hover{
            background-color: $primary;
            & .card__logo{
                color: $white;
            }
        }
    }



    &__circle{
        width: 100%;
        max-width: 350px;
        margin: auto;

        &--blue{
            & .card__logo{
                color: $blue;
            }
        }
    }
    // &__rounded{
    //     border-radius: 1rem;
    // }
    // &__caption{
    //     font-size: 12px;
    //     padding-left: 1.5em;
    //     transition: color 0.3s ease-in 0.1s;
    //     color: $dark;
    //     @include media-breakpoint-up(sm){
    //         font-size: 15px;
    //         padding-left: 0;
    //     }
    // }
    &--blue{
        // @include diagonal-bg($blue);
        // &::after{
        //     @include trinagle($blue);
        // }
        @include media-breakpoint-up(lg){

            &:hover{
                background-color: $blue;
            }
        }

        & .card__logo{
            color: $blue;
        }
    }

    &__logo{
        @extend .img-fluid;
        color: $primary;
        position: relative;
        z-index: 99;
        // width: 66%;
        max-height: 50%;
        // max-height: 160px;
        // max-width: 66%;
        // @include media-breakpoint-up(sm){
        //     height: 200px;
        // }
        // @include media-breakpoint-up(md){
        //     height: 235px;
        // }
        // @include media-breakpoint-up(lg){
        //     height: 120px;
        // }
        // @include media-breakpoint-up(xl){
        //     height: auto;
        // }
    }
}

.box-shadow{box-shadow: 5px 5px 12px rgba(0,0,0,.2);}