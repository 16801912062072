/* Dopa il 09/2017 */
/* credits */
.credits-info .container,
.site-footer .container {
    padding-bottom: 0px;
}

.credits-info {
    background-color: #f1f1f1;
    color: #999;
    text-align: center;
    padding-top: 17px;
    padding-bottom: 17px;
    font-size: 11px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
}

.credits-info svg#purelab_leaf {
    margin: 0px 3px -3px;
}

.credits-info svg#rocket_host {
    margin: 0px 3px -6px;
}

.credits-info a {
    color: #999 !important;
    text-decoration: none !important;
}

#cdd>div {
    display: inline-block;
    margin: 0px 10px;
}

#cdd>div:last-child {
    margin-right: 0px;
}

#cdd>div:first-child {
    margin-left: 0px;
}

#cdd>div svg#purelab_leaf path,
#cdd>div svg#rocket_host path {
    fill: #252525 !important;
}

#cdd>div>span {
    color: #252525 !important;
}

#cdd>div:hover svg#purelab_leaf path,
#cdd>div:hover svg#rocket_host path {
    fill: #333333 !important;
}

.credits-info a:hover,
#cdd>div:hover span,
#cdd>div:hover>span,
#cdd>div:hover a {
    color: #333333 !important;
}

/* credits hover */
#cdd>div,
#cdd>div a,
#cdd>div span,
#cdd>div>span,
#cdd>div svg path {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

#cdd>div:hover a,
#cdd>div:hover span,
#cdd>div:hover>span,
#cdd>div:hover svg path {
    text-decoration: none;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

/* Small */
@media only screen and (max-width : 979px) {
    .credits-info {
        text-align: center;
    }

    #cdd>div:last-child {
        margin-right: 10px;
    }

    #cdd>div:first-child {
        margin-left: 10px;
        margin-bottom: 10px;
    }
}