#wrapper-footer { background-color: #eaeaea; position: relative;
  padding-top: 80px;
  padding-bottom: 100px;

  .crts__widget--footer {
    &:nth-child(2n) { margin-top: 1.5rem; /*spacer di bootsrtap*/ }
  }

  .main {
    .logo { /*margin-top: -1rem;*/ 
      & svg{ height: 92.972px; width: 102.73px; 
        @include media-breakpoint-up(lg) { width: 100%; height: auto;  }
      }
    }

    li { display: inline-block;
      @include media-breakpoint-up(lg) { display: block; }
    }
  }
}
