.modal{

    &-fullscreen{ padding-right: 0 !important; }

    &-fullpage {
        min-width: 100%;
        margin: 0;
    }
    
    &-content { 
        &--fullpage{ 
            min-height: 100vh; 
            background-color: rgba(0,0,0,.9);
            border: 0;
            border-radius: 0;
        }
    }

    &-close{
        position: absolute;
        right: 2rem;
        top: 1rem;
        z-index: 9999;

        & .close{
            font-size: 3rem;
            font-weight: 400;
            line-height: 1;
            color: #fff;
            text-shadow: unset;
            opacity: .5;

        }
    }

    &--form {
        .modal-dialog {
            @include media-breakpoint-up(lg){
                max-width: 70%;
            }
        }

        .modal-header, .modal-body {
            padding-left: 35px;
            padding-right: 35px;
        }

        .modal-body {
            padding-bottom: 90px;
            padding-top: 35px;
        }

        .modal-header {
            padding-top: 40px;
            padding-bottom: 35px;
            border-bottom: 2px solid rgba(#333, .35);

            p {
                font-size: 15px;
            }
        }

        label {
            font-size: 23px;
        }

        .wrapper-privacy {
            input {
                border-radius: 0;
                width: 24px;
                height: 24px;

                @include media-breakpoint-down(md){
                    height: 18px;
                    height: 18px;
                }
            }

            label, a {
                font-size: 23px;

                @include media-breakpoint-down(md){
                    font-size: 16px;
                }
            }

        }

        .form-check {
            margin-top: 40px;
            p {
                font-size: 23px;
            }
        }

        .col-check {
            input {
                display: none;

                &:checked {
                    & + label {
                        background-color: $primary;
                        color: #fff;
                        border-color: $primary;
                    }
                }
            }
    
            label {
                width: 100%;
                color: #333;
                font-weight: bold;
                border-radius: 11px;
                border: 2px solid #333;
                font-size: 20px;
                padding-top: 25px;
                padding-bottom: 25px;
                text-align: center;
                margin-bottom: 14px;
                transition: $transition-base;
                cursor: pointer;

                &:hover {
                    background-color: $primary;
                    color: #fff;
                    border-color: $primary;
                }

                @include media-breakpoint-down(md){
                    font-size: 16px;
                }
            }
        }
    }

}

.carousel{
    &-inner--modal{
        height: 90%;
        height: 90vh;

        &--full{
            height: 100%;
            height: 100vh;
        }
    }

	&-item--modal{
        height: 100%;
        text-align: center;
	}

	&-img--modal{
        height: 98%;
        width: 70%;
        object-fit: contain;
        object-position: center;
        @include object-fit(contain, center);
        padding-top: 1% ;
    }

    &-desc{

        position: static;
        z-index: 15;
        padding: 0;
        margin: 1rem 0 0;
        list-style: none;
        height: 100%;

        & li{

            box-sizing: content-box;
            flex: 0 1 auto;
            width: auto;
            height: auto;
            margin: 0;
            text-indent: unset;
            cursor: unset;
            background-color: unset;
            border: 0;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            transition: all .6s ease-in-out;
            text-align: center;

            &.active{
                visibility: visible;
            }
        }
    }
}
