// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from /sass/bootstrap/_variables.scss, paste it here and edit the value.
// As example you find one of the most important Bootstrap variable below: $brand-primary.
// Thats the ugly Bootstrap blue used for all the links, primary buttons etc.
// Just add your color code here, compile the theme.scss file to change this color everywhere

// ** Color system
$primary: #e31019;
$blue: #489fdf;
$dark: #333333;
$gray: #ccc;
$light-gray: #eaeaea;
$body-color: $dark;

// ** Grid breakpoints
$grid-breakpoints: (
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1366px
);

// ** Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
sm: 540px,
md: 720px,
lg: 960px,
xl: 1336px
);

// ** Typography
$font-family-PP: "Poppins", sans-serif;
$font-family-FT: "FuturaStd-Heavy","Poppins",sans-serif;
$font-family-sans-serif: $font-family-PP;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;

// $h1-font-size: $font-size-base * 2.5 !default;
// $h2-font-size: $font-size-base * 2 !default;
// $h4-font-size: $font-size-base * 1.5 !default;
$h3-font-size: 16px;
$h5-font-size: $font-size-base;
// $h6-font-size: $font-size-base !default;

// ** Global textual link color.
$link-color: $primary;

// ** Link hover color.
$link-hover-color: $dark;
// ** Link hover decoration.
$link-hover-decoration: none;

// ** Nav homepage height
$nav-mobile-height: 90px;
$nav-desktop-height: 130px;

// ** Content Padding homepage
$padding-space: 3.5rem;
