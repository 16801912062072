.col-button {
    .button-modal {
        display: block;
        width: 100%;
        padding: 45px 1rem;
        color: #fff;
        border-color: transparent;
        background-color: transparent;
        border-radius: 11px;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        transition: $transition-base;
        border: 2px solid #fff;

        &:hover {
            background-color: #fff;
            color: #333;
        }
    }
}

.btn-outline {
    width: 100%;
    color: #333;
    font-weight: bold;
    border-radius: 11px;
    border: 2px solid #333;
    font-size: 20px;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    margin-bottom: 14px;
    transition: $transition-base;
    cursor: pointer;

    &:hover {
        background-color: $primary;
        color: #fff;
        border-color: $primary;
    }
}

.find-out-more {
    padding-top: 50px;
    padding-bottom: 90px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    h2 {
        font-size: 44px;
        margin-bottom: 30px;
    }

    .btn-block {
        border: 2px solid #fff;
        background-color: #fff;
        border-radius: 11px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #333;
        font-weight: bold;
        font-size: 20px;
        transition: $transition-base;

        &:hover {
            color: $white;
            background-color: #333;
            border-color: #333;
        }
    }

}

.btn-gradient {
    padding: 24px 1rem;
    background: linear-gradient(90deg, rgba(227,17,24,1) 0%, rgba(71,159,223,1) 74%);
    border-radius: 40px;
    font-size: 20px;
    color: #fff;
    font-weight: bold;

    &:hover {
        color: #fff;
    }
}

.img-fit {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

#webcam {
    display: block;

    @include media-breakpoint-down(md){
        width: 100%;
    }
}

.wrapper-canvas {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        transition: all .5s ease-in-out;
        opacity: 0;
        z-index: 1;
    }

    &.animate {
        &:before {
            background-color: transparent;
            opacity: 1;
        }
    }

    // #canvas {}
}

.wrapper-btns {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        height: 25px;
        width: 25px;
    }

    span {
        font-size: 23px;
    }

    &:before, &:after {
        content: '';
        display: block;
        // width: 25%;
        flex-grow: 1;
        height: 1px;
        background-color: rgba(#333, .25);
    }

    .btn-photo {
        background: transparent;
        border: unset;
    }
}

.btn-snap, .btn-resume {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;

    svg {
        width: 50px;
        height: 50px;
        color: $white;
    }

    span {
        font-size: 1.25rem;
    }
}

// spinner
button .spinner {
    display: none;
}

.wrapper-webcam {
    position: relative;
    .spinner {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .sk-fading-circle {
        color: $primary;
    }

    .sk-fading-circle .sk-circle:before {
        background-color: $primary;
    }
}

.sk-fading-circle {
    color: $white;
    width: 40px;
    height: 40px;
    position: relative;
}

.sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $white;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
            animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
            transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
            transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
            transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
            transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
            transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
            transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
            transform: rotate(300deg); 
}
.sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
            transform: rotate(330deg); 
}
.sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s; 
}
.sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
            animation-delay: -1s; 
}
.sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s; 
}
.sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s; 
}
.sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s; 
}
.sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s; 
}
.sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s; 
}
.sk-fading-circle .sk-circle9:before {
-webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; } 
}

form {
    input[type=text], input[type=email], input[type=tel] {
        height: 50px!important;
    }
    /* Change the white to any color */
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .form-control:invalid {
        background-image: none!important;
    }
}