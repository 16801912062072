p{ @extend .p; }
.p{ font-size: 15px;
    &--sectionHeader {
        margin: 0;
        line-height: 1.5em;
        @include media-breakpoint-up(lg) {
            margin-left: 2.5rem;
        }
    }
    &--marker{ font-size: 1rem;}

    &--meta{ font-size: 1.375rem;  line-height: 1.36em; }

    &--metaTitle{ font-size: 0.8125rem; color: $primary; letter-spacing: 1px; text-transform: uppercase; 
        
    }

    &--footer{ font-size: 15px;}
}

h1{ @extend .h1; }
.h1{
    @extend .h3;
    @extend .h3--focus; 
    @include futura-FT;
}

h2{ @extend .h2; }
.h2{ font-weight: 700; color: $dark; font-size: 1.125rem;  }

h3{ @extend .h3;}
.h3{ 
    font-weight: 700;
    color: $primary;
    @include media-breakpoint-up(md){
        font-size: 24px;
    } 

    &--focus{
        font-size: 24px
    }

    &--home{
        font-size: 1.5rem;
        text-transform: uppercase;
        line-height: 1.3em;
        margin: 0;
    }
}

h4{ @extend .h4; }
.h4{}

h5{ @extend .h5; }
.h5{ text-transform: uppercase; color: $primary;
    &--footer{ font-size: 0.8125em; }
    &--dark{ color: $dark; }
}

h6{ @extend .h6; }
.h6{}

a{ @extend .a; }
.a{ transition: all 0.3s ease; font-size: 15px;
    &:hover { cursor: pointer; }
    &--small{font-size: 0.9375rem; line-height: 1.4em;}

    &--inverted{ color: $dark;
        &:hover{color: $primary;}
    }

    &--white{ color: $white;
        &:hover{color: $white;}
    }

    &:visited{
        color: $dark;
    }
}

.font-FT{ @include futura-FT; }
.font-base{ font-size: $font-size-base; }
.font-lg{ font-size: $font-size-lg; }
.font-sm{ font-size: $font-size-sm; }
.text-blue{color: $blue!important;}

// .text-mobile{
//     font-size: 13px;
//     @include media-breakpoint-up(md) { font-size: 15px; }
// }


